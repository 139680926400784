import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TermineMargin from "../../components/termine-margin"
import WirUeberUnsSubmenu from "../../components/wirueberuns/wirueberuns_submenu"
import WirUeberUnsContentHeader from "../../components/wirueberuns/wirueberuns_contentHeader"
import "../../components/layout.css"

import { graphql } from "gatsby"

export const query = graphql`
  {
    allSanitySiteSettings {
      nodes {
        motto
      }
    }
  }
`

const bildergalerien = ({data}) => (
  <Layout>
    <SEO title="Bildergalerien"/>

    <WirUeberUnsContentHeader headline="UNSERE BILDERGALERIEN" motto={data.allSanitySiteSettings.nodes[0].motto}/>

    <section id="content_2_schule">
      <WirUeberUnsSubmenu/>

      <article id="con_2_rechts">
        <h3 className="small_sub"><span style={{ color: "#007c34" }}>BILDERGALERIEN</span></h3>

        <p className="text"><span style={{ fontWeight: "bold" }}>Auf dieser Seite findest du zu den verschiedenen Aktionen ausgesuchte Bilder. Viel Spa&szlig;! </span><br/><br/>

        </p>

        <br/><br/>
      </article>

      <TermineMargin/>
    </section>
  </Layout>
)

export default bildergalerien
